import React, { DragEvent, useState } from "react";
import SpotDragDirectoryDropArea from "./SpotDragDirectoryDropArea";
import { client } from "../utils/getAxiosClient";
import SpotDragDirectoryAnnounce from "./SpotDragDirectoryAnnounce";

type FileObj = {
  file: File,
  path: string,
}

function SpotDropImages({spot}: {spot: Spot}) {
  const [isUploading, setIsUploading] = useState(false);
  const [announceMsg, setAnnounceMsg] = useState('');

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    setIsUploading(true);
    console.log("アップロード開始");
    const itemLength = e.dataTransfer.items.length;
    const items = e.dataTransfer.items; // D&Dされたファイル/ディレクトリの情報を取得
    const entries:Array<FileSystemFileEntry> = [];

    for(let i = 0; i < itemLength; i++) {
      const item = items[i];
      const entry = item.webkitGetAsEntry();
      if (entry == null) {
        alert('ファイルが見つかりません。再度お試しください。');
        setIsUploading(false);
        return;
      }
      if (!isFile(entry)) {
        alert('ファイルを選択してください。');
        setIsUploading(false);
        return;
      }
      entries.push(entry);
    }

    const files = await getFiles(entries);

    // 画像をアップロード
    await uploadImages(spot.google_place_id, files);
    setAnnounceMsg("アップロードが完了しました。");
    setIsUploading(false);
  }

  if (isUploading) {
    return (
      <div className="bg-white p7 rounded mx-3">
        <div className="top-0 left-0 w-full h-full bg-white opacity-50 z-10">
          <div className="flex flex-col items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
            </svg>
            <p className="m-0">アップロード中です</p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="bg-white p7 rounded mx-3">
        <SpotDragDirectoryAnnounce announceMsg={announceMsg} />
        <SpotDragDirectoryDropArea onDrop={handleDrop}>
          <div x-ref="dnd"
            className="relative flex flex-col text-gray-400 border border-gray-200 border-dashed rounded cursor-pointer">
            <div className="flex flex-col items-center justify-center py-10 text-center">
              <svg className="w-6 h-6 mr-1 text-current-50" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <rect x="3" y="3" width="18" height="18" rx="2"/>
                <circle cx="8.5" cy="8.5" r="1.5"/>
                <path d="M20.4 14.5L16 10 4 20"/>
              </svg>
              <p className="m-0">登録したい画像をここにD&Dしてください</p>
            </div>
          </div>
        </SpotDragDirectoryDropArea>
      </div>
    );
  }
}
export default SpotDropImages;

// ファイルスキャン関数
async function getFiles(entries: Array<FileSystemFileEntry>): Promise<Array<FileObj>> {
  const fileList: Array<FileObj> = []; // 取得したファイルを格納するリスト

  for (let i = 0; i < entries.length; i++) {
    const file = await getFile(entries[i]);
    if (file) {
      fileList.push({ file: file, path: file.name }); // ファイルを取得したらリストにプッシュする
    }
  }
  return fileList;
};

async function getFile(entry: FileSystemEntry) {
  if (!isFile(entry)) {
    return;
  }
  const file: File = await new Promise((resolve) => {
    entry.file((file) => {
      resolve(file);
    });
  });
  return file;
}

function isFile(entry: FileSystemEntry): entry is FileSystemFileEntry {
  return entry.isFile;
}

// 画像をアップロード
async function uploadImages(place_id: string, fileList: Array<FileObj>): Promise<Spot> {
  const formData = new FormData();
  fileList.forEach((file) => {
    formData.append('spot[images][]', file.file);
  });
  // TODO: 画像のアップロードに失敗した場合の処理
  // TODO: 画像が多い場合にも対応できるようにする（並列化？）
  const spot = await client.post(`/admin/spots/${place_id}/images`, formData);
  return spot.data;
}