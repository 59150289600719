import React, { DragEvent, useState } from "react";
import SpotDragDirectoryDropArea from "./SpotDragDirectoryDropArea";
import { client } from "../utils/getAxiosClient";
import SpotDragDirectoryAnnounce from "./SpotDragDirectoryAnnounce";

type FileObj = {
  file: File,
  path: string,
}

function SpotDropDirectory() {
  const [isUploading, setIsUploading] = useState(false);
  const [announceMsg, setAnnounceMsg] = useState('');

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    setIsUploading(true);
    console.log("アップロード開始");
    const item = e.dataTransfer.items[0]; // D&Dされたファイル/ディレクトリの情報を取得
    const entry = item.webkitGetAsEntry(); // ファイル情報からエントリーを取得
    if (entry == null) {
      alert('ディレクトリが見つかりません。再度お試しください。');
      setIsUploading(false);
      return;
    }
    if (entry.isFile) {
      alert('ディレクトリを選択してください。');
      setIsUploading(false);
      return;
    }
    if (!isDirectory(entry)) {
      // ディレクトリじゃない場合は処理を中断
      // alertはisDirectory内で行っているのでここでは不要
      setIsUploading(false);
      return;
    }

    const keyword = entry.name.split('$')[0];
    const google_place_id = entry.name.split('$')[1];
    // ディレクトリ名でspot作成
    await createSpot(keyword, google_place_id);
    // ここでドロップされたディレクトリを渡す
    const fileList = await getFiles(entry);

    // 画像をアップロード
    await uploadImages(google_place_id, fileList);
    setAnnounceMsg("アップロードが完了しました。対象スポット：" + keyword);
    setIsUploading(false);
  };

  if (isUploading) {
    return (
      <div className="bg-white p7 rounded mx-3">
        <div className="top-0 left-0 w-full h-full bg-white opacity-50 z-10">
          <div className="left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="flex flex-col items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
              </svg>
              <p className="m-0">アップロード中です</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="bg-white p7 rounded mx-3">
        <SpotDragDirectoryAnnounce announceMsg={announceMsg} />
        <SpotDragDirectoryDropArea onDrop={handleDrop}>
          <div x-ref="dnd"
            className="relative flex flex-col text-gray-400 border border-gray-200 border-dashed rounded cursor-pointer">
            <div className="flex flex-col items-center justify-center py-10 text-center">
              <svg className="w-6 h-6 mr-1 text-current-50" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <p className="m-0">登録したい画像をディレクトリごとここにD&Dしてください</p>
            </div>
          </div>
        </SpotDragDirectoryDropArea>
      </div>
    );
  }
}
export default SpotDropDirectory

// ファイルスキャン関数
async function getFiles(entry: FileSystemDirectoryEntry, path = null): Promise<Array<FileObj>> {
  const _path = path || "";
  const fileList: Array<FileObj> = []; // 取得したファイルを格納するリスト

  const directoryReader = entry.createReader();
  const entries = await new Promise<FileSystemEntry[]>((resolve) => {
    directoryReader.readEntries((entries) => {
      resolve(entries);
    });
  });

  for (let i = 0; i < entries.length; i++) {
    const file = await getFile(entries[i]);
    if (file) {
      const fileName = _path + entry.name + "/" + file.name;
      fileList.push({ file: file, path: fileName }); // ファイルを取得したらリストにプッシュする
    }
  }
  return fileList;
};

async function getFile(entry: FileSystemEntry) {
  if (!isFile(entry)) {
    return;
  }
  const file: File = await new Promise((resolve) => {
    entry.file((file) => {
      resolve(file);
    });
  });
  return file;
}

function isFile(entry: FileSystemEntry): entry is FileSystemFileEntry {
  return entry.isFile;
}

function isDirectory(entry: FileSystemEntry): entry is FileSystemDirectoryEntry {
  if (!entry.isDirectory) {
    alert('ディレクトリを選択してください。');
    return false;
  }

  if (entry.name.split('$').length != 2) {
    alert('ディレクトリ名が不正です。ディレクトリ名は「スポット名$PlaceID」の形式でお願いします。');
    return false;
  }
  return true;
}

// spotデータ作成
async function createSpot(keyword: string, google_place_id: string): Promise<Spot> {
  const spot = await client.post('/admin/spots', {
    spot: {
      keyword: keyword,
      google_place_id: google_place_id,
    }
  });
  return spot.data;
}

// 画像をアップロード
async function uploadImages(place_id: string, fileList: Array<FileObj>): Promise<Spot> {
  const formData = new FormData();
  fileList.forEach((file) => {
    formData.append('spot[images][]', file.file);
  });
  // TODO: 画像のアップロードに失敗した場合の処理
  // TODO: 画像が多い場合にも対応できるようにする（並列化？）
  const spot = await client.post(`/admin/spots/${place_id}/images`, formData);
  return spot.data;
}