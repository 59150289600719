import React from "react";

// アップロード完了時に表示するメッセージ
function SpotDragDirectoryAnnounce({ announceMsg }: { announceMsg: string }): JSX.Element | null {
  if (announceMsg) {
    return (
      <div className="p-4 mb-4 text-sm text-blue-700 bg-blue-200 rounded-lg border border-gray-200" role="alert">
        <span className="font-medium">{announceMsg}</span>
      </div>
    );
  } else {
    return null;
  }
} export default SpotDragDirectoryAnnounce;