import React from "react";
import { useCallback, useState } from "react";
import update from 'immutability-helper';
import SpotImagesSortableCard from "./SpotImagesSortableCard";

const style = {
  // width: 400,
}

function SpotImagesSortableArea({images, place_id}: {images: Image[] | [], place_id: string}) {
  const [cards, setCards] = useState(images);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    console.log("dragIndex: " + dragIndex + ", hoverIndex: " + hoverIndex);
    setCards((prevCards: Image[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Image],
        ],
      }),
    )
  }, []);

  const removeCard = useCallback((id: number) => {
    console.log("removeCard");
    console.log(id);
    setCards((prevCards: Image[]) =>
      prevCards.filter((card: Image) => card.id !== id)
    )
  }, []);

  const renderCard = useCallback(
    (card: Image, index: number) => {
      return (
        <SpotImagesSortableCard
          key={index}
          index={index}
          place_id={place_id}
          id={card.id}
          text={card.text}
          url={card.url}
          moveCard={moveCard}
          removeCard={removeCard}
        />
      );
    },
    [],
  )

  if (cards.length == 0) {
    return (
      <div>
        <div style={style}>画像がありません</div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={style} className="flex flex-wrap">{cards.map((card, i) => renderCard(card, i))}</div>
      </div>
    );
  }
}
export default SpotImagesSortableArea;