import type { Identifier, XYCoord } from 'dnd-core';
import React from 'react';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { client } from '../utils/getAxiosClient';

const ItemTypes = {
  CARD: 'card',
}
const style = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  cursor: 'move',
  width: '100px',
  height: '100px',
}

export interface CardProps {
  place_id: string
  id: number
  text: string
  index: number
  url: string
  moveCard: (dragIndex: number, hoverIndex: number) => void
  removeCard: (id: number) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

function SpotImagesSortableCard({ place_id, id, text, index, url, moveCard, removeCard }: CardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    // TODO: dropするまでsort表示が変わらない
    drop: (item: DragItem) => {
      console.log("drop");
      console.log(item);
      const formData = new FormData();
      formData.append('index', item['index'].toString());
      client.post(`/admin/spots/${place_id}/images/${id}/sort`, formData);
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  async function deleteImg() {
    if (confirm("画像を削除しますか？")) {
      await client.delete(`/admin/spots/${place_id}/images/${id}`);
      removeCard(id);
    }
  }

  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId} className="m-1 relative">
      <span className="absolute top-[-8px] right-[1px] z-100 text-red-700 font-bold text-3xl cursor-pointer" onClick={deleteImg}>&times;</span>
      <img src={url} className="w-full h-full object-cover cursor-grab" />
    </div>
  )
}
export default SpotImagesSortableCard;