import React, { ReactNode, DragEvent } from "react";

function SpotDragDirectoryDropArea({ children, onDrop }: { children: ReactNode, onDrop: (e: DragEvent<HTMLDivElement>) => void }): JSX.Element {

  const handleDragOver = (e: { stopPropagation: () => void; preventDefault: () => void; }) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onDrop(e);
  };

  return (
    <div  onDragOver={handleDragOver} onDrop={handleDrop} className="p-4 text-gray-400 border border-gray-200 rounded">
      {children}
    </div>
  );
}

export default SpotDragDirectoryDropArea;