import React from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SpotImagesSortableArea from "./SpotImagesSortableArea";

function SpotImagesSort({images, place_id}: {images: Image[], place_id: string}) {

  return (
    <div className="bg-white p7 rounded mx-3">
        <DndProvider backend={HTML5Backend}>
					<SpotImagesSortableArea images={images} place_id={place_id}  />
				</DndProvider>
    </div>
  );
}
export default SpotImagesSort;