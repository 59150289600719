import React from "react";

const SpotUploadImagesArea = () => {
  return (
    <div className="mt-8 lg:w-1/4 lg:mt-0 lg:px-6">
      <h3 className="text-blue-500 capitalize">SpotUploadImagesArea</h3>
    </div>
  );
}

export default SpotUploadImagesArea;